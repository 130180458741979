import { AircraftAmenity } from './aircraft-amenity';
import { AircraftModel } from './aircraft-model';
import { AircraftTail } from './aircraft-tail';
import { AircraftCategory } from './aircraft-category';
import { Operator } from './operator';
import { AircraftImage } from './aircraft-image';

export class Aircraft {
  aircraftId: number;
  generic: boolean = false;
  genericLabel: string;
  tail: AircraftTail = null;
  model: AircraftModel = null;
  category: AircraftCategory = null;
  operator: Operator = null;
  operatorName: string = null;
  operatorUuid: string = null;
  yom: Date = null;
  yor: Date = null;
  amenities: Array<AircraftAmenity> = [];
  images: Array<AircraftImage> = [];
  requiresOwnerApproval = false;
  marketableSeatsCount: number;
  insuranceExpirationDate: Date = null;
  insuranceApproved = false;
  deleted = false;
  completed = false;
  operatorApprovalExpirationDate: Date = null;
  insuranceRestrictionApprover = false;
  notes: string;
}
