import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import { VendorOrganizationUser } from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const LOAD = '[RM Vendor Organization User] Load';
export const LOAD_SUCCESS = '[RM Vendor Organization User] Load Success';
export const LOAD_FAIL = '[RM Vendor Organization User] Load Fail';
export const SAVE_USER = '[RM Vendor Organization User] Save';
export const SAVE_USER_SUCCESS = '[RM Vendor Organization User] Save Success';
export const SAVE_USER_FAIL = '[RM Vendor Organization User] Save Fail';
export const UPDATE_USER = '[RM Vendor Organization User] Update';
export const UPDATE_USER_SUCCESS = '[RM Vendor Organization User] Update Success';
export const UPDATE_USER_FAIL = '[RM Vendor Organization User] Update Fail';
export const RESET = '[RM Vendor Organization User] Reset';
export const SET_SELECTED_USER = '[RM Vendor Organization User] Set Selected User';


export class LoadPayload {
  vendorId: string;
  lookup: viewModels.UsersLookup;
  fetchNetworkOnl: boolean;
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public items: Array<models.VendorOrganizationUser>, public total: number) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class SavePayload {
  constructor(public item: VendorOrganizationUser) {}
}

export class SaveAction implements Action {
  readonly type = SAVE_USER;

  constructor(public payload: SavePayload) {}
}

export class SaveSuccessAction implements Action {
  readonly type = SAVE_USER_SUCCESS;
}

export class SaveFailAction implements Action {
  readonly type = SAVE_USER_FAIL;
}

export class ResetAction implements Action {
  readonly type = RESET;
}

export class SetSelectedUserPayload {
  constructor(public item: VendorOrganizationUser) {}
}

export class SetSelectedUser {
  readonly type = SET_SELECTED_USER;

  constructor(public payload: SetSelectedUserPayload) {}
}


export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | SaveAction
  | SaveSuccessAction
  | SaveFailAction
  | ResetAction
  | SetSelectedUser;
