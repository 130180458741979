import { SortDescriptor } from "@progress/kendo-data-query";
import * as _ from 'lodash';

export class Util {
  static orderStatusCssClasses = [
    { id: 1, cssClass: 'text-black' },
    { id: 2, cssClass: 'text-danger' },
    { id: 3, cssClass: 'text-master-light' },
    { id: 4, cssClass: 'text-warning-dark' },
    { id: 5, cssClass: 'text-complete' },
    { id: 6, cssClass: 'text-warning' },
    { id: 7, cssClass: 'text-success' },
    { id: 8, cssClass: 'bg-danger-light' },
    { id: 9, cssClass: 'text-danger' }
  ];

  static nvlToNumber(value: any): null | number {
    if (value == null) {
      return null;
    } else {
      return Number(value);
    }
  }

  static isDateLessThan24hrs(date: Date) {
    const today = new Date();
    const nextDay = new Date().setDate(today.getDate() + 1);

    return (new Date(date) <= new Date(nextDay));
  }

  static getErrorMessage(errorObject: any): string {
    if (errorObject && errorObject.error && errorObject.error.message) {
      return errorObject.error.message;
    } else {
      return null;
    }
  }

  static addErrorIdToMessage(errorObject: any, errorMessage: string = ''): string {
    if (errorObject.error?.message) {
      errorMessage += ` Message: ${errorObject.error.message}`;
    }
    if (errorObject.error?.errorId) {
      errorMessage += ` Error Id: ${errorObject.error?.errorId}`;
    }

    return errorMessage;
  }

  static getGraphqlErrorMessage(errorObject: any): Array<string> {
    if (errorObject.graphQLErrors) {
      return errorObject.graphQLErrors.map(error => {
        let message = error?.errorMessage || error?.message;
        if(error.errorId) {
          message += ` Error Id: ${error.errorId}`;
        }
        return message;
      }
        );
    }
    else{
      return [];
    }
  }

  static getGraphqlErrorMessagesWithErrorCode(errorObject: any): Array<{message: string; code: string}> {
    if (errorObject.graphQLErrors) {
      return errorObject.graphQLErrors.map(error => ({message: error.errorMessage, code: error.errorCode}));
    }
    else{
      return [];
    }
  }

  static sortDescriptorToSortInput(sort: SortDescriptor): Record<'sortBy' | 'direction', string> {
    /* convert camelCase  to  SNAKE_CASE */
    const sortBy = sort.field.replace(/[A-Z]/g, (letter) => `_${letter}`).toUpperCase();
    const direction = sort.dir === 'asc' ? 'ASC' : 'DESC';
    return {sortBy, direction};
  }

  static getChangedFields<T>(
    pristine: T, 
    edited: T,
    alwaysInclude: (keyof T)[] = []
  ): Partial<T> {
    const changes: Partial<T> = {};
  
    for (const key in pristine) {
      if (!_.isEqual(pristine[key], edited[key]) || alwaysInclude.includes(key)) {
        changes[key] = edited[key];
      }
    }
  
    return changes;
  }
  
}
