import * as models from '../../domain/models';
import * as actions from '../actions/organization-users';
import * as viewModels from '../../view/view-models';
import * as _ from 'lodash';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  users: Array<models.VendorOrganizationUser>;
  selectedUser: models.VendorOrganizationUser
  lookup: viewModels.UsersLookup;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  users: [],
  selectedUser: null,
  lookup: new viewModels.UsersLookup(),
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...state,
        lookup: _.cloneDeep(action.payload.lookup),
        isLoading: true,
        isLoaded: false,
      };
    }

    case actions.LOAD_SUCCESS: {
      const users = _.cloneDeep(action.payload.items);
      const lookup = _.cloneDeep(state.lookup);
      lookup.total = _.cloneDeep(action.payload.total);
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        users,
        lookup,
      };
    }

    case actions.LOAD_FAIL: {
      const lookup = _.cloneDeep(state.lookup);
      return {
        ...initialState,
        isLoaded: true,
        lookup,
      };
    }

    case actions.SAVE_USER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actions.SAVE_USER_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case actions.RESET: {
      return {
        ...initialState,
      };
    }

    case actions.SET_SELECTED_USER: {
      return {
        ...state,
        selectedUser: _.cloneDeep(action.payload.item),
      }
    }

    default: {
      return state;
    }
  }
}

export const getUsers = (state: State) => state.users;
export const getSelectedUsers = (state: State) => state.selectedUser;
export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getLookup = (state: State) => state.lookup;
