export class Permissions {
  clientsModule = {
    overall: false,
    lookup: false,
    universalSearch: false,
  };
  charterSalesModule = {
    overall: false,
    opportunities: false,
    myOpportunities: false,
    newOpportunities: false,
  };
  explorerModule = {
    search: false,
  };
  marketplaceModule = {
    overall: false,
    oneWayQuotes: false,
  };
  resourceModule = {
    overall: false,
    aircraft: false,
    airports: false,
    applicationConfigs: false,
    areas: false,
    markets: false,
    regions: false,
    vendors: false,
    users: false,
  };
  salesModule = {
    overall: false,
    new: false,
    orders: false,
    pricingCalculator: false,
    pricingComparison: false,
  };
  sourcingModule = {
    overall: false,
    requests: false,
    myRequests: false,
    new: false,
  };
  taskMgmtModule = {
    overall: false,
    tasks: false,
  };
  yieldMgmtModule = {
    overall: false,
    heatMap: false,
    configurations: false,
    seatCharts: false,
    seatPricing: false,
    cyoPricing: false,
    charterPricing: false,
  };
  userMgmtModule = {
    overall: false,
    users: false,
  };

  constructor(data: Partial<Permissions> = null) {
    if (data) {
      Object.keys(data).forEach((key) => {
        if (this.hasOwnProperty(key)) {
          this[key] = { ...this[key], ...data[key] };
        }
      });
    }
  }
}
