import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const GET_VISTA_STUDIO_SEARCH_RESULTS = '[Explorer.SearchResults] Get Vista Studio Search Results';
export const GET_VISTA_STUDIO_SEARCH_RESULTS_SUCCESS = '[Explorer.SearchResults] Get Vista Studio Search Results Success';
export const GET_VISTA_STUDIO_SEARCH_RESULTS_FAILED = '[Explorer.SearchResults] Get Vista Studio Search Results Failed';
export const GET_MARKETPLACE_SEARCH_RESULTS = '[Explorer.SearchResults] Get Marketplace Search Results';
export const GET_MARKETPLACE_SEARCH_RESULTS_SUCCESS = '[Explorer.SearchResults] Get Marketplace Search Results Success';
export const GET_MARKETPLACE_SEARCH_RESULTS_FAILED = '[Explorer.SearchResults] Get Marketplace Search Results Failed';
export const SET_LOOKUP = '[Explorer.SearchResults] Set Lookup';
export const RESET_LOOKUP = '[Explorer.SearchResults] Reset Lookup';
export const CREATE_SOURCING_REQUEST = '[Explorer.SearchResults] Create Sourcing Request';
export const CREATE_SOURCING_REQUEST_SUCCESS = '[Explorer.SearchResults] Create Sourcing Request Success';
export const CREATE_SOURCING_REQUEST_FAILED = '[Explorer.SearchResults] Create Sourcing Request Failed';


export class GetVistaStudioSearchResultsAction implements Action {
    readonly type = GET_VISTA_STUDIO_SEARCH_RESULTS;
}

export class GetVistaStudioSearchResultsSuccessPayload {
  constructor(public items: Array<models.SearchResult>) {}
}

export class GetVistaStudioSearchResultsSuccessAction implements Action {
  readonly type = GET_VISTA_STUDIO_SEARCH_RESULTS_SUCCESS;

  constructor(public payload: GetVistaStudioSearchResultsSuccessPayload) {}
}

export class GetVistaStudioSearchResultsFailedAction implements Action {
  readonly type = GET_VISTA_STUDIO_SEARCH_RESULTS_FAILED;
}

export class GetMarketplaceSearchResultsAction implements Action {
  readonly type = GET_MARKETPLACE_SEARCH_RESULTS;
}

export class GetMarketplaceSearchResultsSuccessPayload {
  constructor(public items: Array<models.SearchResult>) {}
}

export class GetMarketplaceSearchResultsSuccessAction implements Action {
  readonly type = GET_MARKETPLACE_SEARCH_RESULTS_SUCCESS;

  constructor(public payload: GetMarketplaceSearchResultsSuccessPayload) {}
}

export class GetMarketplaceSearchResultsFailedAction implements Action {
  readonly type = GET_MARKETPLACE_SEARCH_RESULTS_FAILED;
}

export class SetLookupAction implements Action {
  readonly type = SET_LOOKUP;

  constructor(public payload: viewModels.SearchResultsLookup) {}
}

export class ResetLookupAction implements Action {
  readonly type = RESET_LOOKUP;
}

export class CreateSourcingRequestPayload {
  constructor(
    public clientId: number,
    public legs: Array<models.Leg>,
    public searchResults: Array<models.SearchResult>,
  ) {}
}

export class CreateSourcingRequestAction implements Action {
  readonly type = CREATE_SOURCING_REQUEST;

  constructor(public payload: CreateSourcingRequestPayload) {}
}

export class CreateSourcingRequestSuccessAction implements Action {
  readonly type = CREATE_SOURCING_REQUEST_SUCCESS;
}

export class CreateSourcingRequestFailedAction implements Action {
  readonly type = CREATE_SOURCING_REQUEST_FAILED;
}

export type Actions = 
  | GetVistaStudioSearchResultsAction
  | GetVistaStudioSearchResultsSuccessAction
  | GetVistaStudioSearchResultsFailedAction
  | GetMarketplaceSearchResultsAction
  | GetMarketplaceSearchResultsSuccessAction
  | GetMarketplaceSearchResultsFailedAction
  | SetLookupAction
  | ResetLookupAction;
